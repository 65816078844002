import React from 'react';
import { Col, Row } from 'reactstrap';
import { t } from 'i18next';
import './track-page.scss';

import moment from 'moment';
function WaybillInfo({ data }) {
  return (
    <>
      <h3 className='main_info title'>
        {' '}
        {t('waybill_entry.waybill')} {t('waybill_entry.information')}
      </h3>
      <div className='main_info_container'>
        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.way_bill_no')}: </p>
          </Col>
          <Col>
            <p>
              {data.waybillnumber} ({data.currentStatus})
            </p>
          </Col>
        </Row>

        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.waybill_date')}: </p>
          </Col>
          <Col>
            <p>{moment(data.waybill_info?.shipping_date).format('DD/MM/YYYY')}</p>
          </Col>
        </Row>

        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.destination')}: </p>
          </Col>
          <Col>
            <p>{data.destination?.name}</p>
          </Col>
        </Row>

        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.delivery_company')}: </p>
          </Col>
          <Col>
            <p>{data.waybill_info?.delivery_company}</p>
          </Col>
        </Row>

        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.delivery_place')}: </p>
          </Col>
          <Col>
            <p>{data.createdBranch?.name}</p>
          </Col>
        </Row>
        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.delivery_no')}: </p>
          </Col>
          <Col>
            <p>{data.waybill_info?.delivery_no}</p>
          </Col>
        </Row>
        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.sale_person')}: </p>
          </Col>
          <Col>
            <p>{data.waybill_info?.sale_person?.name}</p>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default WaybillInfo;
