import { t } from 'i18next';
import React from 'react';
import { Col, Row } from 'reactstrap';
import './track-page.scss';

function CustomerInfo({ data }) {
  return (
    <>
      <h3 className='main_info title'>
        {' '}
        {t('customer')} {t('waybill_entry.information')}
      </h3>
      <div className='main_info_container'>
        <Row>
          <Col md={6} style={{ textAlign: 'center', marginBottom: 10 }}>
            <h4>
              {t('waybill_entry.shipper')} {t('waybill_entry.information')}
            </h4>
            <div className='main_info_container'>
              <Row>
                <Col className='main_info_title'>
                  <p>{t('waybill_entry.shipper')}:</p>
                </Col>
                <Col>
                  <p>{data?.waybill_info?.sender?.name}</p>
                </Col>
              </Row>
              <Row>
                <Col className='main_info_title'>
                  <p>{t('waybill_entry.phone')}: </p>
                </Col>
                <Col>
                  <p>{data?.waybill_info?.sender?.phone}</p>
                </Col>
              </Row>
              <Row>
                <Col className='main_info_title'>
                  <p>{t('waybill_entry.company')}: </p>
                </Col>
                <Col>
                  <p>{data?.waybill_info?.sender?.company}</p>
                </Col>
              </Row>
              <Row>
                <Col className='main_info_title'>
                  <p>{t('waybill_entry.address')}: </p>
                </Col>
                <Col>
                  <p>{data?.waybill_info?.sender?.address}</p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={6} style={{ textAlign: 'center', marginBottom: 10 }}>
            <h4>
              {t('waybill_entry.receiver')} {t('waybill_entry.information')}
            </h4>
            <div className='main_info_container'>
              <Row>
                <Col className='main_info_title'>
                  <p>{t('waybill_entry.receiver')}: </p>
                </Col>
                <Col>
                  <p>{data?.waybill_info?.receiver?.name}</p>
                </Col>
              </Row>

              <Row>
                <Col className='main_info_title'>
                  <p>{t('waybill_entry.phone')}: </p>
                </Col>
                <Col>
                  <p>{data?.waybill_info?.receiver?.phone}</p>
                </Col>
              </Row>

              <Row>
                <Col className='main_info_title'>
                  <p>{t('waybill_entry.company')}: </p>
                </Col>
                <Col>
                  <p>{data?.waybill_info?.receiver?.company}</p>
                </Col>
              </Row>

              <Row>
                <Col className='main_info_title'>
                  <p>{t('waybill_entry.address')}: </p>
                </Col>
                <Col>
                  <p>{data?.waybill_info?.receiver?.address}</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CustomerInfo;
