import { t } from 'i18next';
import React from 'react';
import { Col, Row } from 'reactstrap';
import './track-page.scss';

const getCargoType = (value) => {
  if (value <= 300) return 'Light Cargo';
  else if (value <= 600) return 'Light Heavy Cargo';
  else if (value > 600) return 'Heavy Cargo';
  return '';
};

function CargoInfo({ data }) {
  return (
    <>
      <h3 className={'main_info title'}>
        {t('cargo')} {t('waybill_entry.information')}
      </h3>
      <div className='main_info_container'>
        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.item_type')}: </p>
          </Col>
          <Col>
            <p>{data?.waybill_info?.item.itemtype?.name}</p>
          </Col>
        </Row>
        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.shipment_type')}: </p>
          </Col>
          <Col>
            <p>{data?.waybill_info?.shipment_type?.name}</p>
          </Col>
        </Row>
        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.item_name')}: </p>
          </Col>
          <Col>
            <p>{data?.waybill_info?.item?.itemname}</p>
          </Col>
        </Row>
        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.paymentType')}: </p>
          </Col>
          <Col>
            <p>{data?.waybill_info?.cost?.paymentType?.name}</p>
          </Col>
        </Row>
        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.volume')}:(m3): </p>
          </Col>
          <Col>
            <p>{data?.waybill_info?.item?.volume}</p>
          </Col>
        </Row>
        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.item_qty')}: </p>
          </Col>
          <Col>
            <p>{data?.waybill_info?.item?.qty}</p>
          </Col>
        </Row>
        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.value')}: </p>
          </Col>
          <Col>
            <p>{data?.waybill_info?.item?.item_value}</p>
          </Col>
        </Row>
        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.weight')} (Kg): </p>
          </Col>
          <Col>
            <p>{data?.waybill_info?.item?.weight}</p>
          </Col>
        </Row>
        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.cargo_type')}: </p>
          </Col>
          <Col>
            <p>{getCargoType(data?.waybill_info?.item?.weight / data?.waybill_info?.item?.volume)}</p>
          </Col>
        </Row>
        <Col md={6}></Col>
        <Row>
          <Col className='main_info_title'>
            <p>{t('waybill_entry.remark')}: </p>
          </Col>
          <Col>
            <p>{data?.waybill_info?.item?.remark}</p>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CargoInfo;
