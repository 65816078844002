import React from 'react';
import { t } from 'i18next';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import './track-page.css';

const Step = ({ step, mainData }) => {
  const getTrackingDate = (status, stepData) => {
    switch (status) {
      case 'ORDERCREATED':
        return moment(mainData.waybill_info?.order_id?.shipping_date).format('D/MMM/YY');

      case 'WAYBILLCREATED':
        return moment(mainData.waybill_info?.shipping_date).format('D/MMM/YY');

      case 'LOADED':
        return moment(stepData.way?.date).format('D/MMM/YY');

      case 'UNLOADED':
        return moment(stepData.way?.date).format('D/MMM/YY');

      case 'SIGNED':
        return moment(stepData.sign?.date).format('D/MMM/YY');

      default:
        return '';
    }
  };

  const getContent = (status, stepData) => {
    switch (status) {
      case 'WAYBILLCREATED':
        return (
          <CargoReceive
            data={stepData}
            date={getTrackingDate(stepData.status, stepData)}
            branch={getSubStepTitle(stepData.status, stepData)}
          />
        );

      case 'LOADED':
        return (
          <CargoLoading
            data={stepData}
            date={getTrackingDate(stepData.status, stepData)}
            branch={getSubStepTitle(stepData.status, stepData)}
          />
        );

      case 'UNLOADED':
        return (
          <CargoUnLoading
            data={stepData}
            date={getTrackingDate(stepData.status, stepData)}
            branch={getSubStepTitle(stepData.status, stepData)}
          />
        );

      case 'SIGNED':
        return (
          <SignQuery
            data={stepData}
            date={getTrackingDate(stepData.status, stepData)}
            branch={getSubStepTitle(stepData.status, stepData)}
          />
        );

      // case 'COMMENT':
      //   return stepData.content;

      default:
        return <></>;
    }
  };

  const getSubStepTitle = (status, stepData) => {
    switch (status) {
      case 'ORDERCREATED':
        return mainData.createdBranch?.name;

      case 'WAYBILLCREATED':
        return mainData.createdBranch?.name;

      case 'LOADED':
        return stepData?.info?.departure?.name;

      case 'UNLOADED':
        return stepData?.info?.destination?.name;

      case 'SIGNED':
        return `${t('customer_service.signed_label')} by  ${stepData?.info?.sign_info?.name}`;

      default:
        return '';
    }
  };

  return <div>{getContent(step.status, step)}</div>;
};

export default Step;

const CargoReceive = ({ date, branch }) => {
  return (
    <div className='main_info_container '>
      <Row>
        <Col>
          <p>{date}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{branch}</p>
        </Col>
      </Row>
    </div>
  );
};

const CargoLoading = ({ data, date, branch }) => {
  return (
    <div className='main_info_container'>
      <Row>
        <Col>
          <p>{`${date}`}</p>
          <p>{branch}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{`${data?.way?.way_number}`}</p>
          <p>{data?.way?.container}</p>
        </Col>
      </Row>
      <Row>
        <Col className='row_info'>
          <p
            style={{
              marginRight: '10px',
            }}
          >
            {'Qty'}:
          </p>
          <p>{data?.info?.qty}</p>
        </Col>
      </Row>
    </div>
  );
};

const CargoUnLoading = ({ data, date, branch }) => {
  return (
    <div className='main_info_container'>
      <Row>
        <Col>
          <p>{`${date}`}</p>
          <p>{branch}</p>{' '}
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{`${data?.way?.way_number}`}</p>
          <p>{data?.way?.container}</p>{' '}
        </Col>
      </Row>
      <Row>
        <Col className='row_info'>
          <p
            style={{
              marginRight: '10px',
            }}
          >
            {'Qty'}:
          </p>
          <p>{data?.info?.qty}</p>
        </Col>
      </Row>
    </div>
  );
};

const SignQuery = ({ data, date, branch }) => {
  return (
    <div className='main_info_container'>
      <Row>
        <Col>
          <p>{`${date}`}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{data?.sign?.container}</p>
          <p>{branch}</p>
        </Col>
      </Row>
      <Row>
        <Col className='row_info'>
          <p
            style={{
              marginRight: '10px',
            }}
          >
            {'Qty'}:
          </p>
          <p>{data?.sign?.qty}</p>
        </Col>
      </Row>
    </div>
  );
};
